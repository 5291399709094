import React from "react"
import Slider from "react-slick"
import Clutch_img from "../images/clutch1.png"

const testimonial__slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: false,
};

export default () => (
    <section className="home-resourse__row home-clutch__section">
        <div className="container">
            <div className="resourse__lt pd_right_40">
                <h2 className="heading_main">A Word From Our Clients </h2>
                <div className="slider-test">
                    <Slider {...testimonial__slider}>
                        <div className="resourse__lt_testimonials">
                            <div className="home-clutch__section_caption">
                                <p>CueBlocks never cease to amaze me with their infinite knowledge and understanding of every corner of the online sphere.</p>

                            </div>
                            <div className="home-clutch__section_owner">
                                <h3>BettyandBiddy</h3>
                                {/* <h6>Co-Founder at BettyandBiddy</h6> */}
                            </div>
                        </div>
                        <div className="resourse__lt_testimonials">
                            <div className="home-clutch__section_caption">
                                Their technical experience supporting and improving our Magento websites has allowed us to grow considerably as an organization.
                            </div>
                            <div className="home-clutch__section_owner">
                                <h3>PetDoors</h3>
                            </div>
                        </div>
                        <div className="resourse__lt_testimonials">
                            <div className="home-clutch__section_caption">
                                CueBlocks is a world-class team located in a small town. It is impressive to see the caliber of the team and the business practices they follow.
                            </div>
                            <div className="home-clutch__section_owner">
                                <h3>Mojarto</h3>
                            </div>
                        </div>
                        <div className="resourse__lt_testimonials">
                            <div className="home-clutch__section_caption">
                               I have been greatly impressed by their reliability, communication, attention to detail, and most importantly, the high quality of work that they produce for us on a daily basis. 
                            </div>
                            <div className="home-clutch__section_owner">
                                <h3>PlanetJill</h3>
                            </div>
                        </div>
                        <div className="resourse__lt_testimonials">
                            <div className="home-clutch__section_caption">
                                Our experience in outsourcing services has been the most pleasurable and overwhelmingly successful since our involvement with CueBlocks Team.
                            </div>
                            <div className="home-clutch__section_owner">
                                <h3>AMX</h3>
                            </div>
                        </div>
        
                        
                    </Slider>
                </div>
            </div>
            <div className="resourse__rt text-right pd_left_40">
              
                <p>Satisfied clients are the fuel that keeps us on the road to learning, improving, growing, and evolving. Check out what our clients have to say about their association with CueBlocks on Clutch.  </p>
                <ul>
                    <li>
                        <a href=""> <img src={Clutch_img} alt="Clutch" />  </a>
                    </li>
                </ul>
               
            </div>
        </div>

    </section>
)