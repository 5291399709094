import React from "react"
import { Link } from "gatsby"
import SocialMedia from "./social_media-header"


const ListLink = props => (
    <li>
      <Link to={props.to}>{props.children}</Link>
    </li>
)

export default () => (
        <footer>            
            <div className="main-nav__row">
                <div className="container">
                        <ul>
                            <ListLink to="/about">About</ListLink>  
                            <ListLink to="/services">Services</ListLink>  
                            <ListLink to="/work">Work</ListLink>  
                            <ListLink to="/resources">Resources</ListLink>  
                            <ListLink to="/">Store</ListLink>  
                            <ListLink to="/contact">Contact us</ListLink>                              
                        </ul>
                </div>
            </div>
            
            <div className="copyright_row">
                <p>© Copyright since 2005 Cue Blocks Technologies Pvt. Ltd. <br/> All Rights Reserved. Privacy </p>
            </div>    
            <div className="footer-social_icons">
                <SocialMedia />
            </div>
        </footer>

)