import React from "react"
import { Link } from "gatsby"
import Menu from "./menu"
import logo from "../images/logo_wh.png"


export default () => (


    <header> 
        <div className="container"> 
        <div className="header-nav__row">
            <Link to="/" className="header-logo__col">
                <img src={logo} alt="Logo" />
            </Link>
            <Menu />                                
        </div>
        </div>    
    </header>
    
)