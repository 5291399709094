import React from "react"
import { Link } from "gatsby"
const ListLink = props => (
    <li>
      <Link to={props.to}>{props.children}</Link>
    </li>
  )
export default () =>
<div className="submenu--container">
<div className="submenu--container__row">
        <div className="submenu--container__list submenu--container__list-multi servicemenu-heading">
                    <h2>About</h2>
            </div>
            <div className="submenu--container__list submenu--container__list-multi">
                    <ul>
                        <ListLink to="/team">Team</ListLink>
                        <ListLink to="/career">Careers</ListLink>
                                         
                    </ul>
            </div>
            <div className="submenu--container__list submenu--container__list-multi">
            </div>
</div>
</div>