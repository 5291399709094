import React, {Component} from "react"
import Header from "./header"
import Footer from "./footer"
export default class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentRoute: null
        };
    }
    componentDidMount() {
        this.setState({
            currentRoute: (document.location.pathname.replace(new RegExp('/', 'g'),'')==='')?'home':document.location.pathname.replace(new RegExp('/', 'g'),'')
        });

        if (document.location.pathname === '' || document.location.pathname === '/') {
            document.body.classList.add('homepage-body')
        } else {
            document.body.classList.remove('homepage-body')
        }

        let bm_burger_button= document.getElementsByClassName('bm-burger-button')[0];
        bm_burger_button.addEventListener('click',function(){
            document.body.classList.add('no-scroll');
         
        });

        let bm_cross_button = document.getElementsByClassName('bm-cross-button')[0];
        bm_cross_button.addEventListener('click', function () {
            document.body.classList.remove('no-scroll');    
        });
    }
    render() {
        return(
        <div>
            <Header />
            <main id={this.state.currentRoute}>
                {this.props.children}
            </main>
            <Footer />
        </div>
        )
    }
}
