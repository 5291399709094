import React from "react"
import { Link } from "gatsby"
const ListLink = props => (
    <li>
      <Link to={props.to}>{props.children}</Link>
    </li>
  )
export default () => (
  <div className="submenu--container">
    <div className="submenu--container__row">
      <div className="submenu--container__list submenu--container__list-multi servicemenu-heading">
        <h2>Services</h2>
      </div>
      <div className="submenu--container__list submenu--container__list-multi">
        <ul>
          <ListLink to="/magento">Magento</ListLink>
          <ListLink to="/shopify">Shopify</ListLink>
          <ListLink to="/wordpress">Wordpress</ListLink>
          <ListLink to="/php">Php</ListLink>
          <ListLink to="/creative_design">Creative Design</ListLink>
        
        </ul>
      </div>
      <div className="submenu--container__list submenu--container__list-multi">
        <ul>
          <ListLink to="/organic_search">Organic Search</ListLink>
          <ListLink to="/paid_search">Paid Digital Advertising</ListLink>
          <ListLink to="/conversion">Conversion</ListLink>
          <ListLink to="/email-marketing">Email marketing service</ListLink>
        </ul>
      </div>
    </div>
  </div>
)