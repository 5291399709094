import React, { Component } from "react" //useLayoutEffect,useState
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"
import Submenu from "./submenu"
import logo from "../images/logo_black.png"
import cross__icon from "../images/cross__icon.svg"
import Aboutsubmenus from "./aboutsubmenus"


var styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "30px",
    height: "30px",
    right: "2%",
    top: "17px",
  },

  bmBurgerBars: {
    background: "#373a47",
  },

  bmCrossButton: {
    height: "23px",
    width: "23px",
    zIndex: "99",
    cursor: "pointer",
    right: "23px",
    top: "23px",
  },

  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: "0",
    left: "0",
    background: "#fff",
    padding: "20px",
  },
  bmMenu: {
    background: "#fff",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
}

export default class Container extends Component {
  constructor(props) {
    super(props)
    this.state = { width: 0 }
  }


  updateDimensions = () => {
    this.setState({ width: window.innerWidth })
  }
  componentDidMount() {
    this.setState({ width: window.innerWidth })
    window.addEventListener("resize", this.updateDimensions)
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }


  render() {
    const activemenus = {
      fontWeight: "bold",
    }
    const NavLink = props => (
      <li>
        <Link to={props.to} activeStyle={activemenus} activeClassName="active">
          {" "}
          {props.children}
        </Link>
      </li>
    )
    return (
      <div className="menu-header">
        {this.state.width <= 1024 ? (
          <Menu
            width={"100%"}
            styles={styles}
            noOverlay
            customBurgerIcon={
              <div className="mobile-menu">
                <span className="toggle__first"></span>
                <span className="toggle__second"></span>
                <span className="toggle__third"></span>
              </div>
            }
            customCrossIcon={
              <div className="mobile__cross--icon">
                <img src={cross__icon} alt="cross icon" />
              </div>
            }
          >
            <nav className="main-nav__row">
              <ul>
                <NavLink to="/">
                  <img src={logo} alt="Logo" />
                </NavLink>
                <li>
                  <Link to="/about">About</Link>
                  <Aboutsubmenus />
                </li>
                <li>
                  <Link
                    to="/services"
                    activeStyle={activemenus}
                    activeClassName="active"
                  >
                    Services
                  </Link>
                  <Submenu />
                </li>
                <NavLink to="/work">Work</NavLink>
                <NavLink to="/resources">Resources</NavLink>
                <li>
                  <a
                    href="https://store.cueblocks.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Store
                  </a>
                </li>
                <NavLink to="/contact">Contact us</NavLink>
              </ul>
            </nav>
          </Menu>
        ) : (
          <nav className="main-nav__row">
            <ul>
              <li>
                <Link
                  to="/about"
                  activeStyle={activemenus}
                  activeClassName="active"
                >
                  About
                </Link>
                <Aboutsubmenus />
              </li>
              <li>
                <Link
                  to="/services"
                  activeStyle={activemenus}
                  activeClassName="active"
                >
                  Services
                </Link>
                <Submenu />
              </li>
              <NavLink to="/work">Work</NavLink>
              <NavLink to="/resources">Resources</NavLink>
              <li>
                <a
                  href="https://store.cueblocks.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Store
                </a>
              </li>
              <NavLink to="/contact">Contact us</NavLink>
            </ul>
          </nav>
        )}
      </div>
    )
  }
}
